import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import SEO from "../../components/seo";
import Layout from "../../containers/layout/layout";
import Header from "../../containers/layout/header/header-one";
import Footer from "../../containers/layout/footer/footer-one";
import CTA from "../../containers/global/cta-area/section-one";
import PageHeader from "../../components/pageheader";
import SingleCareerOverview from "../../components/careers/SingleCareerOverview";

const CareerTemplate = ({ data, pageContext, location, ...restProps }) => {
  const pageData = data.careersJson;
  const seo = data.careersJson.seo;

  return (
    <Layout location={location}>
      <SEO
        title={seo.title}
        description={seo.description}
        image="/dark-logo.png"
        pathname={`/careers/${pageContext.slug}`}
      />
      <Header />
      <PageHeader
        pageContext={pageContext}
        location={location}
        title={pageData.title}
      />
      <main className="site-wrapper-reveal">
        <SingleCareerOverview
          description={pageData.description}
          title={pageData.title}
          details={pageData.details}
          slug={pageData.fields.slug}
        />
        <CTA />
      </main>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    careersJson(fields: { slug: { eq: $slug } }) {
      title
      description
      sortOrder
      fields {
        slug
      }
      details {
        about
        skills {
          required {
            id
            title
          }
          desired {
            id
            title
          }
        }
        custom {
          hasJSFields
        }
      }
      seo{
        title
        description
      }
    }
  }
`;

CareerTemplate.propTypes = {
  titleStyle: PropTypes.object
};

CareerTemplate.defaultProps = {};

export default CareerTemplate;
